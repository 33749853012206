<template>
  <div id="app">



    <div v-if="!inregister && !inforgot && !inlogin && !inempty" id="navbar"
      class="md-primary md-toolbar md-theme-demo-light md-elevation-4">
      <!--<div v-if="currurl!='/login'&&currurl!='/forgot-password'"  id="navbar" class="md-primary md-toolbar md-theme-demo-light md-elevation-4">-->
      <a href="/select-questionnaire"><img v-if="!inquestionselect && !inhome && !inforgot && !isClinician" id="backbutton"
          src="@/assets/baseline_arrow_back_white_18dp.png" /> </a>
      <a href="/list-questionnaires"><img v-if="!inquestionselect && !inhome && !inforgot && isClinician" id="backbutton"
          src="@/assets/baseline_arrow_back_white_18dp.png" /> </a>
      <p v-if="inquality || incare || inhealth || inheadneck || inquestionselect || inlistquestionnaires" id="title">
        {{ currentgeneralstrings[0] }}</p>
      <!--   <p v-if="inquality" id="title">{{currentgeneralstrings[0]}}<p>
      <p v-if="incare" id="title">{{currentgeneralstrings[0]}}<p>
      <p v-if="inhealth" id="title">{{currentgeneralstrings[0]}}<p>
      <p v-if="inheadneck"  id="title">{{currentgeneralstrings[0]}}</p> 
      <p v-if="inquestionselect"  id="title">{{currentgeneralstrings[0]}}</p>    -->

      <p style="margin-left:auto;margin-right:auto;width:fit-content" v-if="inhome" id="title">
        {{ currentgeneralstrings[3] }}</p>
      <div class="lang-menu">
        <div v-if="lang == 'en'" class="selected-lang en">
          English
        </div>
        <div v-if="lang == 'it'" class="selected-lang it">
          Italiano
        </div>
        <ul>
          <li>
            <span @click="changeToEn()" class="en">English</span>
          </li>
          <li>
            <span @click="changeToIt()" class="it">Italiano</span>
          </li>
        </ul>



      </div>
      <a class="button-style" href="/report">{{ currentgeneralstrings[4] }}</a>
      <a v-if="!isClinician" class="button-style" href="/visit-history">{{ currentgeneralstrings[5] }}</a>
      <div v-if="!inhome" id="userwrapper">
        <p v-if="!isClinician" id="loggedin">{{ currentgeneralstrings[1] }}:{{ id }}</p>
        <p v-if="isClinician" id="loggedin">{{ currentgeneralstrings[1] }}:{{ id }}<span v-if="!inlistquestionnaires">({{firstName}})</span></p>
        <div @click="deleteId()" id="logout">
          <img src="@/assets/baseline_exit_to_app_white_18dp.png" />
          <a href="/login">{{ currentgeneralstrings[2] }}</a>
        </div>
      </div>
    </div>
    <div>

    </div>

    <div id="filler">
    </div>




    <router-view v-model="model"></router-view>
    <!-- <HelloWorld msg="BDFQ"/>-->
    <div class="footer">
    </div>

  </div>


</template>

<script>
/*import HelloWorld from './components/HelloWorld.vue'*/
import { userstore } from './data/userstore.js';


export default {
  name: 'App',
  components: {
    // HelloWorld
  },
  data() {
    return {
      currurl: null,
      incancerinventory: window.location.pathname == "/cancer-behavior-inventory",
      inquestionselect: window.location.pathname == "/select-questionnaire" || window.location.pathname == "/select-questionnaire?alert=success",
      inquality: window.location.pathname == "/qlq-c30",
      inhome: window.location.pathname == "/home",
      incare: window.location.pathname == "/care-received",
      inhealth: window.location.pathname == "/health",
      inheadneck: window.location.pathname == "/head-and-neck-cancer",
      inlogin: window.location.pathname == "/login",
      inregister: window.location.pathname == "/register",
      inforgot: window.location.pathname == "/forgot-password",
      inlistquestionnaires: window.location.pathname == "/list-questionnaires",
      inempty: window.location.pathname == "/",
      generalstringsen: [
        "BD4QoL Questionnaire",
        "Logged in as",
        "Sign out",
        "Welcome to BD4QoL Questionnaire",
        "Report a problem",
        "Questionnaire History"
      ],
      generalstringsit: [
        "Questionario BD4QoL",
        "Autenticato come",
        "Esci",
        "Benvenuti nel questionario di BD4QoL",
        "Segnala un problema",
        "Storico Questionari"
      ],
      currentgeneralstrings: this.generalstringsen,
      model: {},
      //    firstandlastname: userstore.state.firstName+' '+userstore.state.lastName+'('+userstore.state.id+')'
      //firstandlastname: userstore.state.firstName,
      id: userstore.state.id,
      firstName: userstore.state.firstName,
      lang: userstore.state.lang,
      isClinician: userstore.state.isClinician,
    }
  },
  methods: {
    deleteId() {
      userstore.commit('changeId', null);
    },
    changeToEn() {
      userstore.commit('changeLang', "en");
    },
    changeToIt() {
      userstore.commit('changeLang', "it");
    }
  },
  watch: {
    currurl: function () {
      if (window.location.pathname == '/select-questionnaire') {
        this.currurl = window.location.pathname;
      }
      else if (window.location.pathname == '/login') {
        this.currurl = window.location.pathname;
      }
      this.inlistquestionnaires = (window.location.pathname == "/list-questionnaires");
    }
  },
  mounted: function () {
    this.currurl = window.location.pathname
    this.inlistquestionnaires = (window.location.pathname == "/list-questionnaires");
  },
  created: function () {
    if (userstore.state.lang == "en") {

      this.currentgeneralstrings = this.generalstringsen;
    }
    else if (userstore.state.lang == "it") {
      this.currentgeneralstrings = this.generalstringsit;
    }
     this.id = userstore.state.id;
     this.firstName = userstore.state.firstName;
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

/* Style for 'Report a problem' and 'Visit History' button */
.button-style {
  display: inline-block;
  padding: 5px 10px;
  margin-right: 10px;
  color: white !important; /* Force white text */
  border: 1px solid white;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  background-color: transparent;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}
.button-style:hover {
  text-decoration: underline; /* Underline on hover */
  background-color: transparent; /* No background color change */
}
.button-style:active {
  background-color: transparent;
}

/* Style for the visible language button */
.lang-menu .selected-lang {
  display: inline-block;
  min-width: 50px;
  text-align: center;
  margin-right: 15px;
  margin-left: -20px;
  border: 1px solid white;
  border-radius: 5px;
  text-decoration: none; /* Remove underline */
  font-weight: bold;
  font-size: 14px;
  background-color: transparent;
  padding: 5px; /* Vertical padding only */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}
/* Hover effect for the visible language button */
.lang-menu .selected-lang:hover {
  text-decoration: underline; /* Add underline on hover */
}

/* Style for dropdown menu items */
.lang-menu ul li span {
  display: block;
  padding: 5px 10px;
  text-align: left;
  background-color: transparent;
  font-weight: normal;
  font-size: 14px;
  color: black; /* Black text for dropdown items */
}
/* Hover effect for dropdown items */
.lang-menu ul li span:hover {
  text-decoration: underline; /* Add underline on hover */
  background-color: rgba(255, 255, 255, 0.2);
}

</style>
