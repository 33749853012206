<template>
  <div class="visit-history">
    <h1>{{ currentStrings.TITLE }}</h1>

    <!-- Date Range Filter -->
    <div class="date-filter">
      <label for="start-date">{{ currentStrings.FROM_DATE }}:</label>
      <input
          type="date"
          id="start-date"
          v-model="startDate"
          @change="fetchData"
          placeholder="YYYY-MM-DD"
          style="margin-right: 8px;"
      />
      <label for="end-date">{{ currentStrings.TO_DATE }}:</label>
      <input
          type="date"
          id="end-date"
          v-model="endDate"
          @change="fetchData"
          placeholder="YYYY-MM-DD"
      />
      <button @click="clearDateFilter" class="clear-button">{{ currentStrings.CLEAR }}</button>
    </div>

    <!-- Show loading message while data is being fetched -->
    <p v-if="loading" class="loading-message">{{ currentStrings.LOADING }}</p>

    <!-- Show table if data is loaded -->
    <div v-else-if="visits.length">
      <table class="visit-table">
        <thead>
        <tr>
          <th>{{ currentStrings.VISIT_DATE }}</th>
          <th>{{ currentStrings.VISIT_TIMING }}</th>
          <th>{{ currentStrings.STATUS }}</th>
          <th></th> <!-- Column for Export PDF Button -->
        </tr>
        </thead>
        <tbody>
        <tr v-for="visit in visits" :key="visit.id_visit">
          <td>{{ formatOrNA(visit.date, 'date') }}</td>
          <td>{{ formatOrNA(visit.visit_timing) }}</td>
          <td>{{ getStatusText(visit.visit_status) }}</td>
          <td>
            <button
                :class="['pdf-export-button', { disabled: visit.visit_status === '0' || exportLoadingStates[visit.id_visit] }]"
                :disabled="visit.visit_status === '0' || exportLoadingStates[visit.id_visit]"
                @click="exportPDF(visit)"
            >
              {{ exportLoadingStates[visit.id_visit] ? currentStrings.DOWNLOAD : currentStrings.EXPORT_PDF }}
            </button>
          </td>
        </tr>
        </tbody>
      </table>

      <!-- Pagination Controls -->
      <div class="pagination-controls">
        <button
            :disabled="currentPage === 1"
            @click="changePage(currentPage - 1)"
            class="pagination-button"
        >
          {{ currentStrings.PREVIOUS }}
        </button>
        <span>{{ currentStrings.PAGE }} {{ currentPage }} {{ currentStrings.OF }} {{ totalPages }}</span>
        <button
            :disabled="currentPage === totalPages"
            @click="changePage(currentPage + 1)"
            class="pagination-button"
        >
          {{ currentStrings.NEXT }}
        </button>
      </div>
    </div>

    <!-- Show message if no data is found -->
    <p v-else>{{ currentStrings.NO_VISITS }}</p>
  </div>
</template>

<script>
import visitHistoryService from "../data/visit-history/visitHistoryService.js";
import {userstore} from "@/data/userstore";
import * as shared from "@/data/visit-history/shared";
import visitPdfExportService from "@/data/visit-history/visitPdfExportService.js";

export default {
  name: "VisitHistory",
  data() {
    return {
      visits: [], // Store fetched visits
      loading: true, // Loading state
      currentPage: 1, // Current page
      totalPages: 0, // Total pages
      pageSize: 20, // Number of rows per page
      startDate: "", // Start date for date range filter
      endDate: "", // End date for date range filter
      exportLoadingStates: {}, // Store loading states for each visit
      strings: {
        en: {
          TITLE: "Questionnaire History",
          FROM_DATE: "From Date",
          TO_DATE: "To Date",
          CLEAR: "Clear",
          LOADING: "Loading visits...",
          VISIT_DATE: "Questionnaire Date",
          VISIT_TIMING: "Questionnaire Timing",
          STATUS: "Status",
          STATUS_PENDING: "Pending",
          STATUS_COMPLETED: "Completed",
          DOWNLOAD: "Download..",
          EXPORT_PDF: "Export PDF",
          EXPORT_ERROR: "Failed to export PDF. Please try again later.",
          PREVIOUS: "Previous",
          NEXT: "Next",
          PAGE: "Page",
          OF: "of",
          NO_VISITS: "No visits found!",
        },
        it: {
          TITLE: "Storico Questionari",
          FROM_DATE: "Dalla Data",
          TO_DATE: "Alla Data",
          CLEAR: "Cancella",
          LOADING: "Caricamento visite in corso...",
          VISIT_DATE: "Data Questionari",
          VISIT_TIMING: "Tempistica del Questionario",
          STATUS: "Stato",
          STATUS_PENDING: "In sospeso",
          STATUS_COMPLETED: "Completato",
          DOWNLOAD: "Scarica...",
          EXPORT_PDF: "Esporta PDF",
          EXPORT_ERROR: "Esportazione PDF fallita. Per favore riprova più tardi.",
          PREVIOUS: "Precedente",
          NEXT: "Successivo",
          PAGE: "Pagina",
          OF: "di",
          NO_VISITS: "Nessuna visita trovata!",
        },
      },
      currentStrings: {}, // Dynamically updated strings
    };
  },
  methods: {
    /* Fetch visit data with filtering and pagination. */
    async fetchData() {
      try {
        this.loading = true; // Set loading message to true before fetching data
        const token = await shared.fetchToken();
        const studyId = userstore.state.id;
        const { visits, totalPages } =
            await visitHistoryService.fetchCombinedVisitRecordsWithPagination(
                studyId,
                token,
                this.currentPage,
                this.pageSize,
                this.startDate,
                this.endDate
            );
        // Sort visits by date (ascending order: oldest first)
        this.visits = visits.sort((a, b) => new Date(a.date) - new Date(b.date));
        this.totalPages = totalPages;
      } catch (error) {
        console.error("Error fetching visit data:", error);
      } finally {
        this.loading = false; // Set loading to false after fetching data
      }
    },

    /**
     * Map visit status value to the corresponding translated text.
     * @param {string|number} statusValue - The visit status value ("0" or "1").
     * @returns {string} - The translated status text.
     */
    getStatusText(statusValue) {
      if (statusValue === "1" || statusValue === 1) {
        return this.currentStrings.STATUS_COMPLETED;
      } else if (statusValue === "0" || statusValue === 0) {
        return this.currentStrings.STATUS_PENDING;
      } else {
        return "N/A";
      }
    },
    /**
     * Clear the currently applied date filter and refetch the data.
     * Resets the date inputs to empty and refetches all data.
     */
    clearDateFilter() {
      this.startDate = "";
      this.endDate = "";
      this.fetchData();
    },

    /**
     * Change the current page of the paginated data and refetch the data.
     * Updates the page number and retrieves the appropriate data for the selected page.
     * @param {number} newPage - The new page number to load.
     */
    changePage(newPage) {
      this.currentPage = newPage;
      this.fetchData(); // Refetch data for the new page
    },

    /**
     * Export a PDF for the given visit.
     * Currently, this is just a placeholder for later implementation.
     * @param {Object} visit - The visit object for which the PDF will be exported.
     */
    async exportPDF(visit) {
      try {
          // Set loading state to true for this visit (Downloading...)
          this.$set(this.exportLoadingStates, visit.id_visit, true);
          await visitPdfExportService.exportPDF(visit);
      } catch (error) {
          console.error("Error exporting PDF:", error);
          alert(this.currentStrings.EXPORT_ERROR);
      } finally {
          // Set loading state back to false after export is done
          this.$set(this.exportLoadingStates, visit.id_visit, false);
      }
    },

    /**
     * Format a value or return 'N/A' if null/undefined.
     * @param {any} value - The value to format.
     * @param {string|null} type - The type of value (optional, e.g., 'date').
     * @returns {string} The formatted value or 'N/A'.
     */
    formatOrNA(value, type = null) {
      if (value == null) return "N/A";
      if (type === "date") {
        return this.formatDate(value); // date values need special formatting
      }
      return value;
    },

    /**
     * Format a date string into a human-readable format.
     * @param {string} dateString - The date string to format.
     * @returns {string} The formatted date in "MM/DD/YYYY" format.
     */
    formatDate(dateString) {
      const date = new Date(dateString); // Create a Date object from the input date string
      const year = date.getUTCFullYear(); // Extract the full year from the Date object (e.g., 2025)
      // Convert to a string and pad with a leading zero if necessary
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Extract the month (0-indexed) and add 1 to convert to 1-indexed
      const day = String(date.getUTCDate()).padStart(2, '0'); // Extract the day of the month
      return `${month}/${day}/${year}`;
    },
  },
  async mounted() {
    this.currentStrings = this.strings[userstore.state.lang || "en"]; // Set strings based on language
    await this.fetchData(); // fetch while loading the page
  },
  watch: {
    // Watch for language changes and update the strings accordingly
    "userstore.state.lang": function (newLang) {
      this.currentStrings = this.strings[newLang || "en"];
    },
  },
};
</script>

<style scoped>
.visit-history {
  text-align: center;
  margin-top: 20px;
}

.visit-table {
  margin: 20px auto;
  border-collapse: collapse;
  width: 80%;
}

.visit-table th,
.visit-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.visit-table th {
  background-color: #f4f4f4;
}

.loading-message {
  font-size: 16px;
  color: #555;
  margin-top: 20px;
}

.pagination-controls {
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.pagination-button {
  padding: 5px 10px;
  background-color: #3F51B5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.date-filter {
  margin-bottom: 20px;
}

.date-filter label {
  margin-right: 10px;
  font-weight: bold;
}

.date-filter input {
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.clear-button {
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 14px;
  background-color: #3F51B5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.clear-button:hover {
  background-color: #303F9F;
}

.pdf-export-button {
  padding: 5px 10px;
  background-color: #d9534f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pdf-export-button.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>
